import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './CityPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import ChatInput from '../../components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const CityPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cityId, setCityId] = useState(null);

  const cities = [
    { id: 1, name: t('office_riga') },
    { id: 2, name: t('office_london') },
    { id: 3, name: t('office_almaty') },
    { id: 4, name: t('office_belgrade') },
    { id: 5, name: t('office_cyprus') },
    { id: 6, name: t('office_it_hub_m') },
    { id: 7, name: t('office_it_hub_k') },
    { id: 8, name: t('office_i_work_remotely') },
  ];
  
  const getCityNameById = (id) => {
    const city = cities.find(city => city.id === id);
    return city ? city.name : 'Unknown City';
  };
  
  useEffect(() => {
    getUserInfo();
  }, []);

  const redirectIfAuth = (isCityConfirmed, currentQuestionId) => {
    const token = Cookies.get('auth_token');
    
    if (!!token) {
      if (!!isCityConfirmed) {
        if (!!currentQuestionId) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      }
    }
  };

  const getUserInfo = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        setCityId(responseData.cityId);
        redirectIfAuth(responseData.cityConfirmed, responseData.currentQuestionId)
        console.log(responseData);
      })
      .catch((error) => console.error('Error getting user city:', error));
  }

  const confirmCity = (cityId) => {
    const cityRequest = {
      cityId: cityId
    };

    postCsrf(`/user/city`, JSON.stringify(cityRequest))
      .then((response) => {
        const responseData = response.data;
        navigate('/values-text');
      })
      .catch((error) => console.error('Error setting user city:', error));
    
  };

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper)}>
          <div className={classNames(styles.messagesContainer)}>
              <div className={classNames(styles.messageContainer, styles.botMessage)}>
                <div className={classNames(styles.triangle)}></div>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'30px'} variant="h6" align="left">
                        <strong>{t('thanks')}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'30px'} variant="body1" align="left">
                        {t('office_part_one')} {getCityNameById(cityId)}{t('office_part_two')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                          <TransparentButton onClick={() => confirmCity(cityId)}>
                            {t('exactly')}
                          </TransparentButton>
                        </Grid>
                        <Grid item>
                          <TransparentButton onClick={() => navigate('/city-select')}>
                            {t('im_from_another_office')}
                          </TransparentButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
          </div>
          <div className={classNames(styles.chatInputContainer)}>
              <ChatInput onSend={null} />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default CityPage;
