import React, { useState } from 'react';
import ChatMessage from './ChatMessage';
import styles from './Chat.module.scss';
import classNames from 'classnames';
import ChatInput from './ChatInput';
import { getRequest, postCsrf, postCsrfForm } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import ChatAnswerList from './ChatAnswerList';
import { useNavigate } from 'react-router-dom';
import { Snackbar, SnackbarContent } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';


const Chat = ({ initMessage, answers, image, questionType, hasPrompt, handlePromptClick }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([
    { text: initMessage, isUser: false, image: null },
    { text: "", isUser: false, image: image }
  ]);
  const [answerList, setAnswerList] = useState([answers]);

  const [lastUserMessage, setLastUserMessage] = useState(""); 
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [mediaFile, setMediaFile] = useState(null);
  const [isValidating, setIsValidating] = useState(false);

  const resetInfoCooldownCookie = () => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('info_cooldown', 10, { expires: 365 })
    } else {
      Cookies.set('info_cooldown', 10)
    }
  };

  const handleSendMessage = (newMessage) => {
    if (!isValidating) {
      setIsValidating(true);
      if (!!mediaFile) {
        sendAnswerMedia(mediaFile)
      } else {
        sendAnswerText(newMessage)
      }
    }
    
  };

  const sendAnswerText = (newMessage) => {
    const answerRequest = {
      answer: newMessage
    };

    postCsrf(`/question/validate`, JSON.stringify(answerRequest))
      .then((response) => {
        const responseData = response.data;
        resetInfoCooldownCookie();
        Cookies.remove('prompt');
        setIsValidating(false);
        if (responseData && Object.keys(responseData).length > 0) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      })
      .catch((error) => {
        console.error('Error validating question:', error);
        setMessages((prevMessages) => [...prevMessages, { text: newMessage, isUser: true }]);
        setLastUserMessage(newMessage);
        setErrorMessage("Неверный ответ");
        setIsValidating(false);
        setShowError(true);
      });
  };

  const sendAnswerMedia = (mediaFile) => {
    const formData = new FormData();
    formData.append('content', mediaFile);

    postCsrfForm(`/question/content-upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        const responseData = response.data;
        resetInfoCooldownCookie();
        setIsValidating(false);
        if (responseData && Object.keys(responseData).length > 0) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      })
      .catch((error) => {
        setIsValidating(false);
        console.error('Error sending file:', error);
      });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {

      console.log("Uploaded file:", file);

      const mediaMessage = {
        text: "",
        isUser: true,
        image: URL.createObjectURL(file)
      };

      setMessages((prevMessages) => [...prevMessages, mediaMessage]);
      setMediaFile(file);
    }
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <div className={classNames(styles.chatWrapper)}>
      <div className={classNames(styles.messagesContainer)}>
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message.text} isUser={message.isUser} image={message.image}/>
        ))}
      </div>
      <div className={classNames(styles.chatInputContainer)}>
        {answers.length > 0 ? (
          <ChatAnswerList onSend={handleSendMessage} answerList={answers} />
        ) : (
          <>
            <ChatInput onSend={handleSendMessage} questionType={questionType} 
            handleFileUpload={handleFileUpload} 
            hasPrompt={hasPrompt} handlePromptClick={handlePromptClick}/>
          </>
        )}
      </div>
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#d32f2f',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)'
          }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <ErrorIcon style={{ marginRight: '8px' }} />
              {errorMessage}
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

export default Chat;
