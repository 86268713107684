import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './EcommcoinsPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import ChatInput from '../../components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChatAnswerList from '../../components/chat/ChatAnswerList';
import { getRequest } from '../../services/fetch/AxiosHelper';

const EcommcoinsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cityId, setCityId] = useState(null);
  
  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        setCityId(responseData.cityId);
      })
      .catch((error) => console.error('Error getting user city:', error));
  }


  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper)}>
          <div className={classNames(styles.messagesContainer)}>
              <div className={classNames(styles.messageContainer, styles.botMessage)}>
                <div className={classNames(styles.triangle)}></div>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'16px'} variant="h6" align="left">
                        <strong>{t('coins_gift')}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'16px'} variant="h6" align="left">
                        { cityId == 8 ? t('coins_logic_remote') : t('coins_logic') }
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'16px'} variant="body1" align="left">
                        <strong>{t('are_you_ready')}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
          </div>
          <div className={classNames(styles.chatInputContainer)}>
              <ChatAnswerList onSend={() => {navigate('/value-select')}} answerList={[{order: 1, answer: t('yes_of_course')}]} />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default EcommcoinsPage;
