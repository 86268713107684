import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './AuthPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import Chat from '../../components/chat/Chat';
import ChatMessage from '../../components/chat/ChatMessage';
import ChatInput from '../../components/chat/ChatInput';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AuthPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [messages, setMessages] = useState([
    { text: t('auth_text'), isUser: false }
  ]);

  const [lastUserMessage, setLastUserMessage] = useState(""); 

  const handleSendMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, { text: newMessage, isUser: true }]);
    setLastUserMessage(newMessage);
  };

  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      getAuthToken();
    }
 
  }, [messages]);

  const setUserData = (userData) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('auth_token', userData.token, { expires: 365 })

      if (!Cookies.get('language')) {
        Cookies.set('language', userData.language, { expires: 365 })
      }
    } else {
      Cookies.set('auth_token', userData.token)

      if (!Cookies.get('language')) {
        Cookies.set('language', userData.language)
      }
    }
  }

  const getAuthToken = () => {    
    if (lastUserMessage) {

      const authRequest = {
        userCode: lastUserMessage
      };

      postCsrf(`/v1/login`, JSON.stringify(authRequest))
        .then((response) => {
          const responseData = response.data;
          setUserData(responseData)
          setUserLanguage();
          navigate("/city")
        })
        .catch((error) => console.error('Error setting auth token:', error));
    }
  }

  const setUserLanguage = () => { 
    const langRequest = {
      language: Cookies.get('language')
    };

    postCsrf(`/user/language`, JSON.stringify(langRequest))
      .then((response) => {
        const responseData = response.data;
      })
      .catch((error) => console.error('Error setting user language:', error));
  }

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper)}>
          <div className={classNames(styles.messagesContainer)}>
              {messages.map((message, index) => (
              <ChatMessage key={index} message={message.text} isUser={message.isUser} />
              ))}
          </div>
          <div className={classNames(styles.chatInputContainer)}>
              <ChatInput onSend={handleSendMessage} />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default AuthPage;
