import React, { useEffect, useState } from 'react';
import { Grid, Button, InputBase, IconButton, Tooltip, Box } from '@mui/material';
import classNames from 'classnames';
import styles from './ChatInput.module.scss';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PromptIcon from '../../assets/images/promptIcon.svg'
import { Cookies } from "react-cookie-consent";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const ChatInput = ({ onSend, placeholder, inputPrefilled = '', questionType, handleFileUpload, hasPrompt, handlePromptClick }) => {
  const [inputValue, setInputValue] = useState(questionType === "UPLOAD" ? "Press send button when media uploaded" : inputPrefilled);
  const [infoCooldown, setInfoCooldown] = useState(() => {
    const savedCooldown = Cookies.get('info_cooldown');
    return savedCooldown ? parseInt(savedCooldown, 10) : 0;
  });
  const { t } = useTranslation();

  useEffect(() => {
    let timer;

    if (infoCooldown > 0) {
      timer = setInterval(() => {
        const newCooldown = infoCooldown - 1;

        if (Cookies.get('cookie-consent') === "accepted") {
          Cookies.set('info_cooldown', newCooldown, { expires: 365 });
        } else {
          Cookies.set('info_cooldown', newCooldown);
        }

        setInfoCooldown(newCooldown);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [infoCooldown]);

  useEffect(() => {
    const handleCookieChange = () => {
      const savedCooldown = Cookies.get('info_cooldown');
      if (savedCooldown) {
        setInfoCooldown(parseInt(savedCooldown, 10));
      }
    };

    const cookieCheckInterval = setInterval(handleCookieChange, 1000);

    return () => clearInterval(cookieCheckInterval);
  }, []);

  const handleSendMessage = () => {
    if (inputValue.trim()) {
      onSend(inputValue);
      setInputValue('');
    }
  };

  const [promptText, setPromptText] = useState(false);
  const [showHint, setShowHint] = useState(false);

  const toggleHint = async () => {
    if (!showHint) {
      const promptFetchResponse = await handlePromptClick();
      if (!!promptFetchResponse) {
        setPromptText(promptFetchResponse);
      }
    }

    setShowHint(!showHint);
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      handleSendMessage();
      event.preventDefault();
    }
  };

  
  return (
    <Grid container alignItems="center" className={classNames(styles.inputWrapper)}>
      {questionType === "UPLOAD" && (
        <Grid item xs={2} xl={1}>
          <div className={classNames(styles.mediaUploadContainer)}>
            <label htmlFor="media-upload" className={classNames(styles.mediaUploadButton)}>
              <AddPhotoAlternateIcon sx={{width: '40px', height: '40px'}}/>
              <input
                id="media-upload"
                type="file"
                accept="image/*,video/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        </Grid>
      )}

      {(hasPrompt && (infoCooldown == 0)) && (
        <Grid item xs={2} xl={1}>
          <IconButton className={classNames(styles.iconButton)} onClick={toggleHint}>
            <img className={classNames(styles.promptIcon)} src={PromptIcon}></img>
          </IconButton>
          {showHint && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '100px',
              left: '40px',
              backgroundColor: '#6c757d',
              color: '#fff',
              borderRadius: '30px',
              padding: '40px',
              fontSize: '20px',
              fontFamily: 'Inter',
              width: 'auto',
            }}
          >
            <div className={classNames(styles.triangle)}></div>
            {promptText}
          </Box>
          )}
        </Grid>
        
      )}
      
      <Grid item xs={questionType === "UPLOAD" || (hasPrompt && infoCooldown == 0) ? 8 : 10} xl={10}>
        <div className={classNames(styles.inputContainer)}>
          <InputBase
            fullWidth
            value={questionType === "UPLOAD" ? t('media_button') : inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            classes={{ input: styles.input }}
            readOnly={!!inputPrefilled}
          />
        </div>
      </Grid>
      <Grid item xs={2} xl={1} className={classNames(styles.buttonContainer)}>
        <Button className={classNames(styles.sendButton)} onClick={handleSendMessage}>
          <ArrowUpwardIcon sx={{fontSize: '32px', color: 'white'}}/>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChatInput;
