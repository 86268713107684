import React from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './LanguagePage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import { Cookies } from "react-cookie-consent";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LanguagePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLanguageSelect = (language) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('language', language, { expires: 365 })
    } else {

      Cookies.set('language', language)
    }

    i18n.changeLanguage(language);

    const token = Cookies.get('auth_token');
    if (!token) {
      navigate('/auth');
    } else {
      navigate('/city');
    }
    
  };

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <Typography variant="body1" gutterBottom>
          <strong>{t('hi_colleague')}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('language_text')}
        </Typography>
        <Box className={classNames(styles.buttons)}>
          <TransparentButton onClick={() => handleLanguageSelect('EN')}>
            English
          </TransparentButton>
          <TransparentButton onClick={() => handleLanguageSelect('RU')}>
            Русский
          </TransparentButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LanguagePage;
