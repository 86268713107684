import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import classNames from 'classnames';
import styles from './VideoPage.module.scss';
import { Link } from 'react-router-dom';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';

const VideoPage = () => {
  return (
    <Box className={classNames(styles.container)}>
      <Box className={classNames(styles.card)}>
        <video width="100%" height="auto" controls>
          <source src="https://questfactory.online/img/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Link className={classNames(styles.skipButton)} to={`/language`}>
          <TransparentButton>
            SKIP
          </TransparentButton>
        </Link>
    </Box>
  );
};

export default VideoPage;
