import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './ChatMessage.module.scss';
import { Dialog, DialogContent } from '@mui/material'; // Импортируем компоненты из MUI

const ChatMessage = ({ message, isUser, image }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);


  const handleImageClick = (src) => {
    setSelectedImage(src);
    setIsModalOpen(true);
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };


  useEffect(() => {
    const container = document.getElementById(`message-container-${message}`);
    if (container) {
      const images = container.querySelectorAll('img');
      images.forEach((img) => {
        img.style.cursor = 'pointer';
        img.onclick = () => handleImageClick(img.src);
      });
    }
  }, [message]);

  if (!message && !image) {
    return null;
  }

  return (
    <div>
      <div
        className={classNames(styles.messageContainer, {
          [styles.userMessage]: isUser,
          [styles.botMessage]: !isUser,
        })}
      >
        {!isUser && <div className={classNames(styles.triangle)}></div>}
        <div className={classNames(styles.messageBubble, { [styles.fullWidthMessage]: !!image })}>
          {image ? (
            <div className={classNames(styles.imageContainer)}>
              <img
                src={image}
                alt="Attached"
                className={classNames(styles.image)}
                onClick={() => handleImageClick(image)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div
              id={`message-container-${message}`}
              className={classNames(styles.messageText)}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
        {isUser && <div className={classNames(styles.triangle)}></div>}
      </div>


      <Dialog 
        open={isModalOpen} 
        onClose={closeModal} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
        <DialogContent sx={{ backgroundColor: 'transparent' }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Enlarged"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChatMessage;
