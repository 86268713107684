import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Switch, useLocation, useNavigate } from 'react-router-dom';
import LanguagePage from './layouts/languagePage/LanguagePage';
import StartPage from './layouts/start/StartPage';
import VideoPage from './layouts/video/VideoPage';
import Header from './components/header/Header';
import ChatPage from './layouts/chat/ChatPage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.scss';
import CityPage from './layouts/city/CityPage';
import AuthPage from './layouts/auth/AuthPage';
import CitySelectPage from './layouts/city/CitySelectPage';
import ValuesTextPage from './layouts/values/ValuesTextPage';
import EcommcoinsPage from './layouts/ecommcoins/EcommcoinsPage';
import ValueSelectPage from './layouts/values/ValueSelectPage';
import { Cookies } from "react-cookie-consent";
import { getRequest } from './services/fetch/AxiosHelper';
import EndPage from './layouts/end/EndPage';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const nonAuthPaths = [
    "/",
    "/video",
    "/language",
    "/auth",
    "/city",
    "/city-select"
  ]

  useEffect(() => {
    const token = Cookies.get('auth_token');
    
    if (!token) {
      if (!nonAuthPaths.includes(location.pathname)) {
        navigate("/auth");
      }
    } else {
      const isNonAuthPath = nonAuthPaths.includes(location.pathname);
      const isCityPath = location.pathname === "/city" || location.pathname === "/city-select";
  
      if (isNonAuthPath && !isCityPath) {
        getCurrentUserQuestion();
  
        if (!!currentQuestionId) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      }
    }
  }, [location.pathname]);
  
  const getCurrentUserQuestion = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        setCurrentQuestionId(responseData.currentQuestionId);
        if (!!responseData.currentQuestionId) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      })
      .catch((error) => console.error('Error getting user currnet question:', error));
  }

  return (
    <div>
      <Header />
      <div className="page-container">
        <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          timeout={1000}
          classNames="slide"
          unmountOnExit
        >
          <Routes location={location}>
            <Route path="/"  element={<StartPage />} />
            <Route path="/video"  element={<VideoPage />} />
            <Route path="/language"  element={<LanguagePage />} />
            <Route path="/auth"  element={<AuthPage />} />
            <Route path="/city"  element={<CityPage />} />
            <Route path="/city-select"  element={<CitySelectPage />} />
            <Route path="/values-text"  element={<ValuesTextPage />} />
            <Route path="/ecommcoins"  element={<EcommcoinsPage />} />
            <Route path="/value-select"  element={<ValueSelectPage />} />
            <Route path="/chat"  element={<ChatPage />} />
            <Route path="/end"  element={<EndPage />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      </div>
    </div>
  );
};

export default App;