import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './ChatPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import Chat from '../../components/chat/Chat';
import { getRequest } from '../../services/fetch/AxiosHelper';
import { useNavigate } from 'react-router-dom';
import { Cookies } from "react-cookie-consent";

const ChatPage = () => {
  const navigate = useNavigate();
  const [currentQuestion, SetCurrentQuestion] = useState(null);
  const [currentSequence, SetCurrentSequence] = useState(null);
  const [currentPrompt, SetCurrentPrompt] = useState(null);

  const resetInfoCooldownCookie = () => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('info_cooldown', 10, { expires: 365 })
    } else {
      Cookies.set('info_cooldown', 10)
    }
  };

  const setPromptCookie = (prompt) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('prompt', prompt, { expires: 365 })
    } else {
      Cookies.set('prompt', prompt)
    }
  };

  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      navigate("/auth")
    } else {
      getCurrentSequence();
      getQuestion();
    }
    
  }, []);

  const getPrompt = () => {  
    const cookiePrompt = Cookies.get('prompt');
    if (!!currentPrompt || !!cookiePrompt) {
      return Promise.resolve(currentPrompt ? currentPrompt : cookiePrompt);
    } else {
      return getRequest(`/question/prompt`)
        .then((response) => {
          const responseData = response.data;
          SetCurrentPrompt(responseData.text);
          setPromptCookie(responseData.text);
          return responseData.text;
        })
        .catch((error) => {
          console.error('Error getting current prompt:', error);
          return Promise.resolve("Not enough coins for hint :(");
        });
    }
  };

  const getQuestion = () => {    
    getRequest(`/question/current`)
      .then((response) => {
        const responseData = response.data;
        SetCurrentQuestion(responseData);
        resetInfoCooldownCookie();
      })
      .catch((error) => console.error('Error getting current question:', error));
  }

  const getCurrentSequence = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        SetCurrentSequence(responseData.sequence)
      })
      .catch((error) => console.error('Error getting current sequence:', error));
  }

  return (
    <Box
      className={classNames(
        styles.container,
        currentSequence === 'Inspirational' && styles.inspirational,
        currentSequence === 'Vibrant' && styles.vibrant,
        currentSequence === 'Collegiate' && styles.collegiate,
        currentSequence === 'Impactful' && styles.impactful
      )}
    >    
      <Box className={classNames(styles.messageBox)}>
        {currentQuestion ? (
          <Chat initMessage={currentQuestion.question} answers={currentQuestion.answerCaseList} 
          image={currentQuestion.image} questionType={currentQuestion.type} 
          hasPrompt={currentQuestion.hasPrompt} handlePromptClick={getPrompt} />
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </Box>
  );
};

export default ChatPage;
