import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './ValueSelectPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import ChatInput from '../../components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const ValueSelectPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [availableSequences, SetAvailableSequences] = useState([]);

  const buttons = [
    { text: 'Inspirational', bgcolor: '#AE91FF', hoverbgcolor: '#7B47E5'},
    { text: 'Vibrant', bgcolor: '#FFCC00', hoverbgcolor: '#276B29'},
    { text: 'Collegiate', bgcolor: '#60F9AA', hoverbgcolor: '#E65C00'},
    { text: 'Impactful', bgcolor: '#1F9CFF', hoverbgcolor: '#026199'},
  ];

  useEffect(() => {
    getCurrentUserQuestion();
    getRequest(`/sequence/available`)
    .then((response) => {
      
      const responseData = response.data;
      SetAvailableSequences(responseData);
      
      if (responseData.length == 0) {
        navigate('/end');
      }
    })
    .catch((error) => console.error('Error setting user sequence:', error));
    
  }, []);
  
  const handleValueSelect = (sequence) => {
    const sequenceRequest = {
      sequence: sequence
    };

    postCsrf(`/sequence/select`, JSON.stringify(sequenceRequest))
      .then((response) => {
        const responseData = response.data;
        setSequenceCookie(sequence);
        navigate('/chat');
      })
      .catch((error) => console.error('Error setting user sequence:', error));
    
  };

  const setSequenceCookie = (sequence) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('current_sequence', sequence, { expires: 365 })
    } else {
  
      Cookies.set('current_sequence', sequence)
    }
  };

  const getCurrentUserQuestion = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        if (!!responseData.currentQuestionId) {
          navigate('/chat');
        }
      })
      .catch((error) => console.error('Error getting user currnet question:', error));
  }

  const isButtonDisabled = (button) => {
    if (availableSequences) {
      return !availableSequences.includes(button.text);
    }
    
    return false;
  };

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper, {[styles.onlyButtons]: availableSequences.length != 4})}>
          <div className={classNames(styles.messagesContainer)}>
              { availableSequences.length == 4 && (
              <div className={classNames(styles.messageContainer, styles.userMessage)}>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'30px'} variant="h6" align="left">
                        <strong>{t('yes_of_course')}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classNames(styles.triangle)}></div>
              </div>
              )}
              { availableSequences.length == 4 && (
              <div className={classNames(styles.messageContainer, styles.botMessage)}>
                <div className={classNames(styles.triangle)}></div>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'30px'} variant="h6" align="left">
                        {t('choose_value')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div> 
              )}
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container justifyContent="center" spacing={2}>
                  {buttons.map((button, index) => (
                    <Grid item key={index}>
                      <Button
                        onClick={() => handleValueSelect(button.text)}
                        variant="contained"
                        disabled={isButtonDisabled(button)}
                        sx={{
                          backgroundColor: button.bgcolor,
                          color: 'white',
                          borderRadius: '20px',
                          padding: '10px 20px',
                          fontWeight: 'bold',
                          '&:hover': {
                            backgroundColor: button.hoverbgcolor,
                          },
                        }}
                      >
                        {button.text}
                      </Button>
                    </Grid>
                  ))}
                  </Grid>
                </Grid>
              </Grid>
          </div>
          { availableSequences.length == 4 && (
          <div className={classNames(styles.chatInputContainer)}>
              <ChatInput onSend={() => {}} inputPrefilled={t('select_value')}/>
          </div>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ValueSelectPage;
