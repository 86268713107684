import React from 'react';
import { Button } from '@mui/material';
import classNames from 'classnames';
import styles from './TransparentButton.module.scss'; // Import the SCSS module

const TransparentButton = ({ children, ...props }) => {
  return (
    <Button
      variant="contained"
      className={classNames(styles.transparentButton)}
      {...props}
    >
      {children}
    </Button>
  );
};

export default TransparentButton;
