import React from 'react';
import { Grid, Button } from '@mui/material';
import classNames from 'classnames';
import styles from './ChatAnswerList.module.scss';

const ChatAnswerList = ({ onSend, answerList }) => {
  const handleAnswerClick = (answer) => {
    onSend(answer);
  };


  const sortedAnswerList = answerList.sort((a, b) => a.order - b.order);

  return (
    <Grid container spacing={2} className={classNames(styles.answerListWrapper)}>
      {sortedAnswerList.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Button
            fullWidth
            variant="contained"
            className={classNames(styles.answerButton)}
            onClick={() => handleAnswerClick(item.answer)}
          >
            {item.answer}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChatAnswerList;
