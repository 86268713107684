import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import classNames from 'classnames';
import styles from './StartPage.module.scss';
import Logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { Cookies } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const StartPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const currentLang = Cookies.get('language');

    if (currentLang != "EN" && currentLang != "RU") {
      if (Cookies.get('cookie-consent') === "accepted") {
        Cookies.set('language', "EN", { expires: 365 })
      } else {
        Cookies.set('language', "EN")
      }
    }
    
  }, []);

  return (
    <Box className={classNames(styles.container)}>
      <Box className={classNames(styles.shadow1)}></Box>
      <Box className={classNames(styles.shadow2)}></Box>
      <Box className={classNames(styles.card)}>
        <img src={Logo} alt="Logo" className={classNames(styles.logo)} />
        <Typography variant="h3" className={classNames(styles.title)}>
          ecommpay <strong>AI</strong>
        </Typography>
        <Link to={`/video`}>
          <Button
            variant="contained"
            className={classNames(styles.startButton)}
          >
            {t('start')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default StartPage;
