import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './ValuesTextPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import ChatInput from '../../components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ValuesTextPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper)}>
          <div className={classNames(styles.messagesContainer)}>
              <div className={classNames(styles.messageContainer, styles.botMessage)}>
                <div className={classNames(styles.triangle)}></div>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'16px'} variant="h6" align="left">
                        <strong>{t('thank_you_ecommpay')}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'16px'} variant="h6" align="left">
                        {t('i_will_tell_you_process')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'16px'} variant="body1" align="left">
                        {t('four_values_ecommpay')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
          </div>
          <div className={classNames(styles.chatInputContainer)}>
            <TransparentButton children={">>"} onClick={() => navigate('/ecommcoins')} />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ValuesTextPage;
