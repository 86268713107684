import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Импорт локальных переводов
import translationEN from './locales/EN/translation.json';
import translationRU from './locales/RU/translation.json';

const resources = {
  EN: {
    translation: translationEN
  },
  RU: {
    translation: translationRU
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'EN',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      lookupCookie: 'language',
      caches: ['cookie']
    },
    load: 'languageOnly'
  });

export default i18n;
