import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './CityPage.module.scss';
import TransparentButton from '../../components/buttons/transparentButton/TransparentButton';
import ChatInput from '../../components/chat/ChatInput';
import { useNavigate } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const CitySelectPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buttons = [
    { text: t('riga'), cityId: "1", bgcolor: '#AE91FF', hoverbgcolor: '#7B47E5', textcolor: '#4B0082' },
    { text: t('almaty'), cityId: "3", bgcolor: '#102E19', hoverbgcolor: '#276B29', textcolor: '#79F5A7' },
    { text: t('cyprus'), cityId: "5", bgcolor: '#AD00FD', hoverbgcolor: '#E65C00', textcolor: '#FF5F00' },
    { text: t('it_hub_k'), cityId: "7", bgcolor: '#1F9CFF', hoverbgcolor: '#026199', textcolor: '#105CD4' },
    { text: t('london'), cityId: "2", bgcolor: '#4B007C', hoverbgcolor: '#3C0F72', textcolor: '#AE91FF' },
    { text: t('belgrade'), cityId: "4", bgcolor: '#79F5A7', hoverbgcolor: '#00B350', textcolor: '#102E19' },
    { text: t('it_hub_m'), cityId: "6", bgcolor: '#FF5F00', hoverbgcolor: '#D93300', textcolor: '#AD00FD' },
    { text: t('i_work_remotely'), cityId: "8", bgcolor: '#105CD4', hoverbgcolor: '#1C54B2', textcolor: '#1F9CFF' },
  ];
  
  useEffect(() => {
    getUserInfo();
  }, []);

  const redirectIfAuth = (isCityConfirmed, currentQuestionId) => {
    const token = Cookies.get('auth_token');
    
    if (!!token) {
      if (!!isCityConfirmed) {
        if (!!currentQuestionId) {
          navigate('/chat');
        } else {
          navigate('/value-select');
        }
      }
    }
  };

  const getUserInfo = () => {    
    getRequest(`/user/info`)
      .then((response) => {
        const responseData = response.data;
        redirectIfAuth(responseData.cityConfirmed, responseData.currentQuestionId)
        console.log(responseData);
      })
      .catch((error) => console.error('Error getting user city:', error));
  }

  const handleCitySelect = (cityId) => {
    const cityRequest = {
      cityId: cityId
    };

    postCsrf(`/user/city`, JSON.stringify(cityRequest))
      .then((response) => {
        const responseData = response.data;
        navigate('/values-text');
      })
      .catch((error) => console.error('Error setting user city:', error));
    
  };

  return (
    <Box className={classNames(styles.container)}>      
      <Box className={classNames(styles.messageBox)}>
        <div className={classNames(styles.chatWrapper)}>
          <div className={classNames(styles.messagesContainer)}>
              <div className={classNames(styles.messageContainer, styles.botMessage)}>
                <div className={classNames(styles.triangle)}></div>
                <div className={classNames(styles.messageBubble)}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography fontFamily={'Inter'} fontSize={'30px'} variant="h6" align="left">
                        <strong>{t('wrong_office')}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="center" spacing={2}>
                      {buttons.map((button, index) => (
                        <Grid item key={index}>
                          <Button
                            onClick={() => handleCitySelect(button.cityId)}
                            variant="contained"
                            sx={{
                              backgroundColor: button.bgcolor,
                              color: button.textcolor,
                              borderRadius: '20px',
                              padding: '10px 20px',
                              fontWeight: 'bold',
                              '&:hover': {
                                backgroundColor: button.hoverbgcolor,
                              },
                            }}
                          >
                            {button.text}
                          </Button>
                        </Grid>
                      ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
          </div>
          <div className={classNames(styles.chatInputContainer)}>
              <ChatInput onSend={null} />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default CitySelectPage;
