import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Menu, MenuItem, Popover, Snackbar, Alert, SnackbarContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PublicIcon from '@mui/icons-material/Public';
import classNames from 'classnames';
import styles from './Header.module.scss';
import style from './Header.scss'
import Logo from '../../assets/images/logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Cookies } from "react-cookie-consent";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const setUserLanguage = () => { 
    const langRequest = {
      language: Cookies.get('language')
    };

    postCsrf(`/user/language`, JSON.stringify(langRequest))
      .then((response) => {
        const responseData = response.data;
        navigate(location.pathname, { replace: true });
      })
      .catch((error) => console.error('Error setting user language:', error));
  }

  const handleLanguageChange = (language) => {
    if (Cookies.get('cookie-consent') === "accepted") {
      Cookies.set('language', language, { expires: 365 })
    } else {
      Cookies.set('language', language)
    }

    i18n.changeLanguage(language);

    handleLanguageClose();
    setUserLanguage();
  };

  const isInfoOpen = Boolean(infoAnchorEl);
  const infoId = isInfoOpen ? 'info-popover' : undefined;

  return (
    <Box className={classNames(styles.header)}>
      <Grid container alignItems="center">
        <Grid item xs={8} md={10}>
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
            <img src={Logo} alt="Logo" style={{ marginRight: '10px', marginLeft: '40px' }} />
            ecommpay AI
          </Typography>
        </Grid>
        <Grid item xs={4} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '-40px' }}>
          <IconButton sx={{ color: 'white' }} onClick={handleInfoClick}>
            <InfoIcon sx={{ fontSize: '1.5em' }} />
          </IconButton>
          <Popover
            id={infoId}
            open={isInfoOpen}
            anchorEl={infoAnchorEl}
            onClose={handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              },
            }}
          >
            <div class="hint" dangerouslySetInnerHTML={{ __html: t('instructions') }} />
          </Popover>
          <IconButton sx={{ color: 'white' }} onClick={handleLanguageClick}>
            <PublicIcon sx={{ fontSize: '1.5em' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleLanguageChange('EN')}>EN</MenuItem>
            <MenuItem onClick={() => handleLanguageChange('RU')}>RU</MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box className={classNames(styles.divider)} />
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#f57c00',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)'
          }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <WarningAmberOutlinedIcon style={{ marginRight: '8px' }} />
              Hint will be available in 5 minutes after you get new question.
            </span>
          }
        />
      </Snackbar>
    </Box>
  );
};

export default Header;
